import {applyDefaultOptions} from "../DefaultOptionsMapper";
import {applyDefaultStyle, applyDefaultWidgetStyle} from "../DefaultStyleMapper";
import {
    FloatingWidgetConfigurationInput,
    FloatingWidgetType
} from "../../../../Contracts/WidgetTypes/FloatingWidgetType";
import {Locale,} from "../../../../Constants/locales";
import {stringToLocale} from "../LocaleMapper";

export default (config: FloatingWidgetConfigurationInput, instanceId: string):FloatingWidgetType => {
    return {
        ...config,
        instanceId,
        options: applyDefaultOptions(config.options ?? {}),
        trigger: !(config.trigger instanceof HTMLElement || Array.isArray(config.trigger)) ? applyDefaultStyle(config.trigger ?? {}) : config.trigger as HTMLElement[],
        widgetStyle: applyDefaultWidgetStyle(config.widgetStyle ?? {}),
        experiencesForVouchers: config.experiencesForVouchers ?? [],
        experiencesForTickets: config.experiencesForTickets ?? [],
        localisation: config?.localisation ?? {} as Record<Locale, Record<string, string>>,
        locale: config.locale ? config.locale : stringToLocale(window.navigator.language),
        checkoutTheme: config.checkoutTheme ?? 'light',
        openOnLoad: config.openOnLoad ?? false,
    }
}
