import {GlobalEventBus} from "../GlobalEventBus";
import {AFFILIATE_LINK_EVENTS} from "../../Events/AffiliateLink/Events";
import {IAffiliateLinkService} from "../../Contracts/GloablServices/IAffiliateLinkService";

const AFFILIATE_LINK_URL_PARAM = 'bklink';
export class AffiliateLinkService implements IAffiliateLinkService {

    private affiliateLink: string | false = false;
    constructor(
        private readonly eventBus: typeof GlobalEventBus,
    ) {
        const affiliateLink = this.getAffiliateLinkFromUrl();
        this.setAffiliateLink(affiliateLink);
    }

    private getAffiliateLinkFromUrl() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(AFFILIATE_LINK_URL_PARAM) ?? false;
    }

    private setAffiliateLink(affiliateLink: string | false) {
        this.affiliateLink = affiliateLink;
        if (affiliateLink) {
            this.eventBus.emit(AFFILIATE_LINK_EVENTS.AFFILIATE_LINK_DETECTED, {affiliateLink});
        }
    }

    getAffiliateLink() {
        return this.affiliateLink;
    }
}