import {SessionService} from "./SessionService/SessionService";
import {IInsights} from "../Contracts/IInsights";
import {getLogger} from "../Services/LoggingService";
import {CheckoutApi} from "./SessionService/CheckoutApi";
import {PosthogAnalytics} from "./InternalAnalytics/PosthogAnalytics";
import {GlobalEventBus as eventBus} from "./GlobalEventBus";
import {GtmAnalytics} from "./GtmAnalytics/GtmAnalytics";
import {ABTester} from "./ABTester/ABTester";
import {IABTesterService} from "./ABTester";
import {LocalEnrollmentStorage} from "./ABTester/FrontendExperimentService/LocalEnrollmentStorage";
import {AffiliateLinkService} from "./AffiliateLinkService/AffiliateLinkService";
import {IAffiliateLinkService} from "../Contracts/GloablServices/IAffiliateLinkService";

declare global {
    interface Window {
        // Old widget mounts this object
        BookingKitApp?: any;
        bookingkitServices: {
            eventBus: typeof eventBus;
            sessionService: SessionService;
            loggerService: ILogger;
            internalAnalyticsService: IInsights;
            abTester: IABTesterService;
            affiliateLinkService: IAffiliateLinkService;
        }
    }
}

const loggerService = getLogger();
const sessionService =  new SessionService(
    {
        checkoutApi: new CheckoutApi({
            baseUrl:  import.meta.env.VITE_BK_CHECKOUT_API_URL as string
        }),
        loggerService: loggerService,
        eventBus
    });

const postHogConfig= {
    projectId: import.meta.env.VITE_POSTHOG_PROJECT,
    api_host: import.meta.env.VITE_POSTHOG_HOST,
    autocapture: false,
    capture_pageview: false,
    capture_pageleave: false
}
const internalAnalyticsService = new PosthogAnalytics({
    logger: loggerService,
    eventBus,
    config: postHogConfig
});

const analytics  = new GtmAnalytics({eventBus});
analytics.init();

const abTester = new ABTester(new LocalEnrollmentStorage(), eventBus);

const affiliateLinkService = new AffiliateLinkService(eventBus);
sessionService.init()
    .catch((e) => loggerService.error(e));

window.bookingkitServices = {
    sessionService,
    eventBus,
    loggerService,
    internalAnalyticsService,
    abTester,
    affiliateLinkService
}
