import {BaseWidgetConfiguration, BaseWidgetConfigurationInput} from "./BaseWidget";
import {BookNowTriggerStyle, BookNowTriggerStyleInput} from "./BookNowTriggerStyle";
export const BOOK_NOW_WIDGET_TYPE = 'experienceBookNowWidget';
export const BOOK_NOW_WIDGET_CONFIGURATION_VERSION = '0.0.5';

// #region BookNowWidgetType
export interface BookNowWidgetType extends BaseWidgetConfiguration {
    type:  typeof BOOK_NOW_WIDGET_TYPE,
    /**
     *  The experiences for which to sell tickets
     */
    experienceId: string,
    trigger: BookNowTriggerStyle | HTMLElement[],
    id: string,
    openOnLoad: boolean,
}
// #endregion BookNowWidgetType

export interface BookNowWidgetConfigurationInput extends BaseWidgetConfigurationInput {
    experienceId: string,
    trigger?: BookNowTriggerStyleInput,
    id: string,
    type:  typeof BOOK_NOW_WIDGET_TYPE,
    /** @default "false" */
    openOnLoad?: boolean,
};




